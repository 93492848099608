<script setup lang="ts">
  import eclipseForm from "../form-element/resource-form-base.vue";
  import type { VideoFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import { type FormWidgetData } from "../../../widgetDataTs.js";
  import modalBody from "./videoForm/modal-body.vue";
  import { computed, ref, onBeforeUnmount , watch, type Ref } from "vue";
  import type { YouTubePlayer } from "../../../utils/YouTube/YouTubePlayer.js";
  import type { LinkDownloadType } from "../../../tracking/LinkDownloadType.js";
  import type { Nil } from "@mcwd/typescript-type-guards";
  import { useFormLogger } from "../../composables/use-form-logger.js";
  import { useFormServices } from "../../composables/use-form-services.js";
  const props = defineProps<{
    formSettingsKey: VideoFormSettingsKey;
    widgetData: FormWidgetData<"VideoForm">;
    customData?: Record<string,any>;
  }>();

  const { logger } = useFormLogger();

  const documents = computed(() => {
    return props.widgetData.setup.form.document.selected;
  });
  const video = computed(() => {
    return props.widgetData.setup.form.video.selected;
  });
  const ctaType: Ref<LinkDownloadType | Nil> = computed(() => props.widgetData.setup.tracking.ctaType);
  const ctaText: Ref<string | Nil> = computed(() => props.widgetData.setup.tracking.ctaText);
  const playerId = `yt-form-vid-${props.widgetData.widget.instanceId}`;
  const ytPlayerDiv = ref<HTMLElement | null>(null);
  

  let YtPlayer = ref<YouTubePlayer | null>(null);

  function cleanUpVideo() {
    if (YtPlayer.value !== null) {
      logger.debug("cleanUpVideo");
      (YtPlayer.value.player as YT.Player).destroy();
      YtPlayer.value = null;
    }
  }
  onBeforeUnmount(() => {
    cleanUpVideo();
  });
  watch(() => ytPlayerDiv.value, function (divCurrentVal) {
    if (divCurrentVal === null) {
      cleanUpVideo();
    }
    else if (YtPlayer.value === null) {
      logger.debug("initialize: ", playerId);
      YtPlayer.value = new window.YouTubePlayer(playerId, {
        videoId: video.value.youTubeId,
        width: 640,
        height: 360,
        playerVars: { autoplay: 1 }
      });
    }
  });

  const { FormState, CurrentFormExperienceGatingState } = useFormServices({ callerLabel: "videoForm" });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm" || CurrentFormExperienceGatingState.value === "NoGate";
  });

</script>

<template>
  <modalBody :video="video" :documents="documents" :cta-type="ctaType" :cta-text="ctaText">
    <template #ytPlayer>
      <div :id="playerId" ref="ytPlayerDiv" :title="video.title"></div>
    </template>
    <template #form>
      <eclipseForm v-if="!showUnlockedView" :form-settings-key="formSettingsKey" :widget-data="widgetData" :custom-data="customData" />
    </template>
  </modalBody>
</template>