<script lang="ts" setup>
  import type { DocumentResource, VideoResource } from '../../../../widgetDataTs.js';
  import { computed } from 'vue';
  import { useFormServices } from "../../../composables/use-form-services.js";
  import ResourceHeading from './resource-heading.vue';
  import RelatedResources from './related-resources.vue';
  import type { LinkDownloadType } from "../../../../tracking/LinkDownloadType.js";
  import type { Nil } from '@mcwd/typescript-type-guards';
  //const isLoaded = ref(true);
  defineProps<{
    video: VideoResource,
    documents: DocumentResource[],
    ctaType?: LinkDownloadType | Nil,
    ctaText?: string | Nil
  }>();

  const { FormState, CurrentFormExperienceGatingState } = useFormServices({ callerLabel: "videoForm" });
  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm" || CurrentFormExperienceGatingState.value === "NoGate";
  });
</script>

<template>
  <div id="eclipse-modal-content" class="eclipseBodyContent">
    <div class="eclipse-form-root js-formBuilderRoot mc-video-form neutral">
      <div class="eclipse-content-wrpr">
        <div class="left-col video dark">
          <div class="video-shadow ratio ratio-16x9">
            <!-- youtube embedded iframe gets filled by intitializeYouTubeIframe function -->
            <div class="youtubePlayer">
              <slot name="ytPlayer"></slot>
            </div>
          </div>
          <ResourceHeading :title="video?.title" :show-unlocked-view="showUnlockedView" /> 
          <!--Pre Submit related resources-->
          <RelatedResources v-if="!showUnlockedView" :documents="documents" :show-unlocked-view="showUnlockedView" />
        </div>
        <div class="prospect-form" style="position:relative;">
          <!--Post submit downloads-->
          <RelatedResources v-if="showUnlockedView" :documents="documents" :show-unlocked-view="showUnlockedView" :cta-type="ctaType" :cta-text="ctaText" />
          <slot name="form"></slot>
          <div class="form-ungated-message after-ungated"></div>
        </div>
      </div>
    </div>
  </div>
</template>

