<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script lang="ts" setup>
  import type { LinkDownloadType } from "../../../../tracking/LinkDownloadType.js";
  import type { DocumentResource } from '../../../../widgetDataTs.js';
  import { useFormAssetDownload } from '../../../composables/use-form-asset-download.js';
  import type { Nil } from '@mcwd/typescript-type-guards';
  import imageWithFallback from '../../../../shared-components/image-with-fallback.vue';
  import { useTranslation } from "i18next-vue";
  import { TranslationNamespaceEnum } from "../../../locale/settings/translation-namespaces.js";
  import { computed } from "vue";
  import { useFormLogger } from "../../../composables/use-form-logger.js";
  import { useFormServices } from "../../../composables/use-form-services.js";
  const props = withDefaults(
    defineProps<{
      documents: DocumentResource[];
      showUnlockedView: boolean;
      ctaType?: LinkDownloadType | Nil;
      ctaText?: string | Nil;
    }>(), { ctaType: "button", ctaText: "" });

  const { logger } = useFormLogger();

  const { downloadResourceTracking } = useFormAssetDownload();

  const numberOfDocs = computed(() => props.documents.length);

  const defaultIcon = "https://www.mastercontrol.com/images/default-source/mcui-design-system/icons/gradient/icon-gradient-doc-3-400x400.png";

  function getDocumentDownloadUrl(legacyId: string, lang: string) {
    return `/customapi/DocumentDownload/GetDocument/${legacyId}?lang=${lang}`;
  }

  function toggleDescription(e) {
    var parentResource = $(e.target).closest('.resource-row');
    var activeCheck = parentResource.hasClass('active');
    if (activeCheck) {
      parentResource.removeClass('active');
      parentResource.find('.info-expand').slideUp();
      parentResource.find('.fal.fa-minus').removeClass('fa-minus').addClass('fa-plus');
    }
    else {
      parentResource.addClass('active');
      parentResource.find('.info-expand').slideDown();
      parentResource.find('.fal.fa-plus').removeClass('fa-plus').addClass('fa-minus');
    }
    logger.debug(e, e.target);
    logger.debug("Event toggle");
  }
  const { t } = useTranslation();
  const { CurrentFormExperienceGatingState } = useFormServices({ callerLabel: "video related resources" });

</script>

<template>
  <div class="related-resources">
    <div v-if="showUnlockedView" class="form-ungated-message after-ungated">
      <div class="blind-submit-video">
        <h5><strong> {{ t("video-BlindSubmitHeading", { ns: TranslationNamespaceEnum.postSubmitText }) }}</strong></h5>
      </div>
    </div>
    <p class="requested">
      Related Resources: ({{ numberOfDocs }})
    </p>
    <div class="r-scroll">
      <div class="r-accordion">
        <div class="gradient-border">
          <ul class="resource-list">
            <li v-for="doc in documents" :key="doc.id" class="resource-row">
              <a v-if="showUnlockedView" 
                :href="getDocumentDownloadUrl(doc.legacyId, doc.language)"
                class="shown-doc-links document item-icon"
                @click="downloadResourceTracking({ asset: doc, ctaType: ctaType ?? 'button', ctaText: ctaText ?? '' })"
              >
                <!-- <imageWithFallback :src="doc.icon" :alt="doc.docType" :fallback-src="defaultIcon"/> -->
                <p class="resource-heading">{{ doc.title }}</p>
                <i class="fal fa-arrow-to-bottom info-click"></i>
              </a>
              <template v-else>
                <div class="tease-doc-links document" 
                  :data-resource-type="doc.docType"
                  @click="$event => toggleDescription($event)"
                >
                  <imageWithFallback class="doc-icon" :src="doc.icon" :alt="doc.docType" :fallback-src="defaultIcon" />
                  <p class="resource-heading">
                    {{ doc.title }}
                  </p>
                  <i class="fal fa-plus info-click"></i>
                </div>
                <div class="info-expand" style="display: none;">
                  <p>{{ doc.description }}</p>
                </div>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Related Resources Below Message -->
    <!-- <div v-if="showUnlockedView && CurrentFormExperienceGatingState !== 'NoGate'" class="form-ungated-message after-ungated">
      <div class="blind-submit-video">
        <p> {{ t("video-BlindSubmitBody", { ns: TranslationNamespaceEnum.postSubmitText }) }}<br><a href="https://www.mastercontrol.com/resource-center/">Visit the Resource Center</a></p>
      </div>
    </div> -->
  </div>
</template>