import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "resources-header" }
const _hoisted_2 = {
  key: 0,
  class: "before-complete"
}
const _hoisted_3 = { class: "primary-heading" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.showUnlockedView)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString($props.title), 1 /* TEXT */)
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}