import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["modalBody"], {
    video: $setup.video,
    documents: $setup.documents,
    "cta-type": $setup.ctaType,
    "cta-text": $setup.ctaText
  }, {
    ytPlayer: _withCtx(() => [
      _createElementVNode("div", {
        id: $setup.playerId,
        ref: "ytPlayerDiv",
        title: $setup.video.title
      }, null, 8 /* PROPS */, _hoisted_1)
    ]),
    form: _withCtx(() => [
      (!$setup.showUnlockedView)
        ? (_openBlock(), _createBlock($setup["eclipseForm"], {
            key: 0,
            "form-settings-key": $props.formSettingsKey,
            "widget-data": $props.widgetData,
            "custom-data": $props.customData
          }, null, 8 /* PROPS */, ["form-settings-key", "widget-data", "custom-data"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["video", "documents", "cta-type", "cta-text"]))
}